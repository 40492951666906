import React, {useState,useEffect} from "react";
import Screen from "../shared/screen";

import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BuySellLog from "./buyselllog";
import CashDivLog from "./cash_div";
import CapChangeLog from "./capchange";
import InterestLog from "./intlog";
import CashDepositsLog from "./cashlog";

import "./style.css";

const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
  });

function LogsTabs(props){
    let {value,setValue} = props;
    let productView = localStorage.getItem("productView");
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("logIndex",newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        className="logTabs"
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{
            style: {
              backgroundColor: "#191d63",
            },
          }}
      >
        {/* <Tab label="Buy & Sell Log" /> */}
        {(productView === "Snowball" || productView == "Batik Fractal") && (<Tab label="Cash Dividends" />)}
        {(productView === "Snowball" || productView == "Batik Fractal") && (<Tab label="Capital Change" />)}
        <Tab label="Money Market" />
        <Tab style={{maxWidth:500}} label="Deposits & Withdrawals" />
      </Tabs>
    </Paper>
  );
}

function renderBody(props){
    return(
        <div className="row">
            <div className="col">
                {LogsTabs(props)}
            </div>
        </div>
    )
}


function LogsPage(){
    const [value, setValue] = React.useState(localStorage.getItem("logIndex")?Number(localStorage.getItem("logIndex")):0);
    const [portfolio, setPortfolio] = useState(localStorage.getItem("t_id"));
    const [timeStamp, setTS] = useState(new Date().getTime());
    let obj = {value,setValue};
    let productView = localStorage.getItem("productView");

    useEffect(()=>{
        document.title = "ETSW - Logs";
    },[]);


    function changePortfolio(t_id){
      setPortfolio(t_id);
    }

    function invokeRefresh(){
      setTS(new Date().getTime());
    }

    return(
        <div>
            <Screen origin="logs" setPortfolio={changePortfolio} invokeRefresh={invokeRefresh}>
            <div className="container-fluid pt-4">
                {renderBody(obj)}
                {/* {value === 0 && <BuySellLog trading_id={portfolio} ts={timeStamp}/>} */}
                {(productView == "Snowball" || productView == "Batik Fractal") && value === 0 && <CashDivLog trading_id={portfolio} ts={timeStamp}/>}
                {(productView == "Snowball" || productView == "Batik Fractal") && value === 1 && <CapChangeLog trading_id={portfolio} ts={timeStamp}/>}
                {(((productView == "Snowball" || productView == "Batik Fractal") && value === 2) || (productView == "Digital Asset Tracker" && value === 0)) && <InterestLog trading_id={portfolio} ts={timeStamp}/>}
                {(((productView == "Snowball" || productView == "Batik Fractal") && value === 3) || (productView == "Digital Asset Tracker" && value === 1)) && <CashDepositsLog trading_id={portfolio} ts={timeStamp}/>}
            </div>
            <br/>
            </Screen>
        </div>
    )
}

export default LogsPage;